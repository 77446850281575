import React, { Component } from 'react';
import './Rsvp.css';
import PersonFormRow from './components/PersonFormRow';
import homeIcon from './resources/rsvpHomeIcon.svg';

let axios = require('axios');
let https = require('https');

class Rsvp extends Component {
    constructor(props) {
        super(props);

        this.bodyRef = React.createRef();

        this.state = {
            data: [],
            formSubmitted: false,
            isLoading: true,
            error: null,
            uniqueCode: null,
            headerBackgroundShouldBeVisible: false
        };

        this.fetchDetails = this.fetchDetails.bind(this);
        this.generatePersonFormRows = this.generatePersonFormRows.bind(this);
        this.onRowDataChange = this.onRowDataChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onGroupKeyChange = this.onGroupKeyChange.bind(this);
        this.loadGroupWithKey = this.loadGroupWithKey.bind(this);
        this.generateHeaderContent = this.generateHeaderContent.bind(this);
        this.generateBodyContent = this.generateBodyContent.bind(this);
        this.onHomeButtonClick = this.onHomeButtonClick.bind(this);
        this.onGoogleCalendarLinkClick = this.onGoogleCalendarLinkClick.bind(this);
        this.onCalendarFileDownloadClick = this.onCalendarFileDownloadClick.bind(this);
        this.atLeastOnGuestIsAttending = this.atLeastOnGuestIsAttending.bind(this);
        this.listenScrollEvent = this.listenScrollEvent.bind(this);
    }

    componentDidMount() {
        if (this.props.params.id == null) {
            return;
        }

        this.fetchDetails(this.props.params.id);
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.params.id !== prevProps.params.id) {
            this.fetchDetails(this.props.params.id);
        }
    }

    listenScrollEvent(event) {
        var updatedheaderBackgroundShouldBeVisible = event.target.scrollTop > 0;
        if (this.state.headerBackgroundShouldBeVisible !== updatedheaderBackgroundShouldBeVisible) {
            this.setState({
                headerBackgroundShouldBeVisible: event.target.scrollTop > 0
            });
        }
    }

    fetchDetails(uniqueKey) {
        let agent = new https.Agent({
            rejectUnauthorized: false
        });

        axios.get("https://picketcats.com:33333/rsvp/details/" + this.props.params.id,
        {
            httpsAgent: agent
        })
        .then((response) => {
            this.setState({
                data: response.data,
                isLoading: false
            });
        })
        .catch((error) => {
            this.setState({
                error: error,
                isLoading: false
            });
        });
    }

    generatePersonFormRows(data) {
        var rowsToRender = [];
        for (let rowIndex = 0; rowIndex < data.length; rowIndex++) {
            var dataRow = data[rowIndex];
            rowsToRender.push(<PersonFormRow data={dataRow} key={dataRow[0]} onChange={this.onRowDataChange}/>);
        }

        return rowsToRender;
    }

    onRowDataChange(updatedRowData) {
        if (updatedRowData.length > 0) {
            var updatedData = [];

            for (let rowIndex = 0; rowIndex < this.state.data.length; rowIndex++) {
                const row = this.state.data[rowIndex];

                if (row.length > 0) {
                    if (updatedRowData[0] === row[0]) {
                        updatedData.push(updatedRowData);
                    }
                    else {
                        updatedData.push(row);
                    }
                }
                else {
                    updatedData.push(row);
                }
            }

            this.setState({
                data: updatedData
            });
        }
    }

    onSubmit(event) {
        this.setState({
            isLoading: true
        });

        let agent = new https.Agent({
            rejectUnauthorized: false
        });

        axios.post("https://picketcats.com:33333/rsvp/update", {
            httpsAgent: agent,
            data: this.state.data
        })
        .then((response) => {
            this.setState({
                formSubmitted: true,
                isLoading: false
            });
        })
        .catch((error) =>  {
            this.setState({
                error: error,
                isLoading: false
            });
        });
    }

    onMoreInfoClick(event) {
        var win = window.open('https://ziyandadavid.com', '_blank');
        win.focus();
    }

    onGroupKeyChange(event) {
        this.setState({
            uniqueCode: event.target.value
        });
    }

    loadGroupWithKey(key) {
        this.props.router.push("/" + key);
    }

    onGoogleCalendarLinkClick() {
        window.location.assign('https://calendar.google.com/event?action=TEMPLATE&tmeid=N2tyNnJkaXQzODFpcmtlY3B0azAwYmdsbTIgZGF2aWRjYWRkeS5jb21fNmg1MjdxOTBtOXQ5bnNzZnRybG5vOTY0bXNAZw&tmsrc=davidcaddy.com_6h527q90m9t9nssftrlno964ms%40group.calendar.google.com');
    }

    onHomeButtonClick() {
        window.location.assign('https://ziyandadavid.com');
    }

    onCalendarFileDownloadClick() {
        const link = document.createElement("a");
        link.href = process.env.PUBLIC_URL + "/iCal-ZD-Wedding.ics";
        link.download = "iCal-ZD-Wedding.ics";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    atLeastOnGuestIsAttending() {
        for (let rowIndex = 0; rowIndex < this.state.data.length; rowIndex++) {
            const row = this.state.data[rowIndex];

            if (row.length >= 5) {
                if (row[4].toLowerCase() === "yes") {
                    return true;
                }
            }
        }

        return false;
    }

    generateHeaderContent() {
        return (
            <div className="RsvpHeaderContainer">
                <button title="Go to main site" className="RsvpHomeButton" onClick={this.onHomeButtonClick}>
                    <img src={homeIcon} alt="Home Button Icon"/>
                </button>
                <div className="RsvpSiteTitle">
                    Ziyanda & David
                </div>
            </div>
        );
    }

    generateBodyContent() {
        if (this.props.params.id == null) {
            return (
                <div className="RsvpCenterColumnSpan">
                    <div className="RsvpCenterColumnSpan">
                        <div className="RsvpLandingPageMessage">
                            Please enter your unique code:
                        </div>
                        <input className="RsvpLandingPageTextField" type="text" name="firstname" onChange={this.onGroupKeyChange} onKeyPress={(event) => {
                            if (event.key === "Enter") {
                                this.loadGroupWithKey(this.state.uniqueCode)
                            }
                        }}>
                        </input>
                        <button title="Submit code" className="RsvpLandingPageSubmitButton" onClick={() => this.loadGroupWithKey(this.state.uniqueCode)}>Submit</button>
                    </div>
                </div>
            );
        }

        if (this.state.error != null) {
            return (
                <div className="RsvpCenterColumnSpan">
                    <div className="RsvpErrorMessage">
                        Something went wrong.
                    </div>
                    <div className="RsvpErrorMessage">
                        Please reload and try again.
                    </div>
                </div>
            );
        }

        if (this.state.isLoading) {
            return (
                <div className="RsvpCenterColumnSpan">
                    <div className="RsvpActivityIndicator"/>
                </div>
            );
        }

        if (this.state.formSubmitted) {
            let atLeastOnGuestIsAttending = this.atLeastOnGuestIsAttending();

            return (
                <div className="RsvpCenterColumnSpan">
                    <div className="RsvpSuccessMessage">
                        Submitted successfully.
                    </div>
                    <div className="RsvpSuccessMessage">
                        {atLeastOnGuestIsAttending ? "Looking forward to seeing you there!" : "Thank you for your response."}
                    </div>
                    <button title="Wedding website" className={atLeastOnGuestIsAttending ? "WebsiteLinkButton" : "PersonFormRowHidden"} onClick={this.onMoreInfoClick}>Head over to the wedding website for more details about the day.</button>
                    <button title="Add event to Google Calendar" className={atLeastOnGuestIsAttending ? "RsvpSuccessPageCalendarButton" : "RsvpHidden"} onClick={() => this.onGoogleCalendarLinkClick()}>Add to Google Calendar</button>
                    <button title="Download iCal File" className={atLeastOnGuestIsAttending ? "RsvpSuccessPageCalendarButton" : "RsvpHidden"} onClick={() => this.onCalendarFileDownloadClick()}>Download iCal File</button>
                    <div className={atLeastOnGuestIsAttending ? "RsvpSuccessPageDescriptionMessage" : "PersonFormRowHidden"}>
                        (For Apple Calendar & Outlook)
                    </div>
                </div>
            );
        }

        return (
            <div className="RsvpCenterColumnSpan">
                <div className="RsvpContent">
                    {this.generatePersonFormRows(this.state.data)}
                </div>
                <button title="Submit form" className={this.state.data.length > 0 ? "RsvpSubmitButton" : "PersonFormRowHidden"} onClick={this.onSubmit}>Submit</button>
                <button title="More Information" className={this.state.data.length > 0 ? "MoreInfoButton" : "PersonFormRowHidden"} onClick={this.onMoreInfoClick}>More Information</button>
            </div>
        );
    }

    render() {
        return (
            <div className="AppContainer">
                <header className={this.state.headerBackgroundShouldBeVisible ? "RsvpHeader" : "RsvpHeaderClear"}>
                    {this.generateHeaderContent()}
                </header>
                <div className="Rsvp" onScroll={this.listenScrollEvent}>
                    <div className="RsvpBody">
                        {this.generateBodyContent()}
                    </div>
                </div>
            </div>
        );
    }
}

export default Rsvp;