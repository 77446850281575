import React, { Component } from 'react';
import './PersonFormRow.css';
import DCCheckbox from './DCCheckbox';

class PersonFormRow extends Component {

    constructor(props) {
        super(props);

        this.state = {
            attending: false,
            dietaryRequirements: "",
            comments: ""
        };

        var isAttending = false;
        var initialDietaryRequirements = "";
        var initialComments = "";
        if (this.props.data.length >= 5) {
            if (this.props.data[4].toLowerCase() === "yes") {
                isAttending = true;
            }
        }
        if (this.props.data.length >= 6) {
            initialDietaryRequirements = this.props.data[5];
        }
        if (this.props.data.length >= 7) {
            initialComments = this.props.data[6];
        }

        this.state = {
            attending: isAttending,
            dietaryRequirements: initialDietaryRequirements,
            comments: initialComments,
        };

        this.onCheckboxChange = this.onCheckboxChange.bind(this);
        this.onDietaryRequirementsChange = this.onDietaryRequirementsChange.bind(this);
        this.onCommentsChange = this.onCommentsChange.bind(this);
        this.onDataChange = this.onDataChange.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        if ((this.state.attending !== prevState.attending)
        || (this.state.dietaryRequirements !== prevState.dietaryRequirements)
        || (this.state.comments !== prevState.comments)) {
            this.onDataChange();
        }
    }

    onCheckboxChange(event) {
        this.setState({
            attending: event.target.checked
        });
    }

    onDietaryRequirementsChange(event) {
        this.setState({
            dietaryRequirements: event.target.value
        });
    }

    onCommentsChange(event) {
        this.setState({
            comments: event.target.value
        });
    }

    onDataChange() {
        var updateData = this.props.data.slice(0);

        if (updateData.length < 7) {
            while (updateData.length < 7) {
                updateData.push("")
            }
        }
        updateData[4] = this.state.attending ? "YES" : "NO";
        updateData[5] = this.state.dietaryRequirements;
        updateData[6] = this.state.comments;

        this.props.onChange(updateData);
    }

    render() {
        var fullNameString = "Guest";
        if ((this.props.data[2] != null) && (this.props.data[2].length > 0)) {
            fullNameString = this.props.data[2];

            if ((this.props.data[3] != null) && (this.props.data[3].length > 0)) {
                fullNameString += " " + this.props.data[3];
            }
        }

        return (
            <div className="PersonFormRow">
                <div className="PersonFormRowContentContainer">
                    <div className="PersonFormRowNameLabel">
                        {fullNameString}
                    </div>
                    <div className="PersonFormRowCheckboxContainer">
                        <DCCheckbox className="PersonFormRowDCCheckboxt" checked={this.state.attending} title="Will be attending:" onChange={this.onCheckboxChange}/>
                    </div>
                </div>
                <div className={this.state.attending ? "PersonFormRowTextBoxesContainer" : "PersonFormRowHidden"}>
                    <div className="PersonFormRowDietaryTextBoxContainer">
                        <div className="PersonFormRowDietaryTextBoxLabel">
                            Dietary requirements:
                        </div>
                        <textarea className="PersonFormRowDietaryTextBox" ref="ietaryRequirementsInput" value={this.state.dietaryRequirements} onChange={this.onDietaryRequirementsChange}/>
                    </div>
                    <div className="PersonFormRowCommentsTextBoxContainer">
                        <div className="PersonFormRowCommentsTextBoxLabel">
                            Other notes:
                        </div>
                        <textarea className="PersonFormRowCommentsTextBox" ref="commentsInput" value={this.state.comments} onChange={this.onCommentsChange}/>
                    </div>
                </div>
            </div>
        );
    }
}

export default PersonFormRow;
