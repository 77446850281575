import React, { Component } from 'react';
import './DCCheckbox.css';
import checkboxCheckedImage from '../resources/rsvpCheckboxCheckedImage.svg';
import checkboxUncheckedImage from '../resources/rsvpCheckboxUncheckedImage.svg';

class DCCheckbox extends Component {

    constructor(props) {
        super(props);

        this.toggleCheckboxValue = this.toggleCheckboxValue.bind(this);
    }

    toggleCheckboxValue(event) {
        var eventObject = {};
        eventObject.target = this;
        eventObject.target.checked = !this.props.checked;

        this.props.onChange(eventObject);
    }

    render() {
        return (
            <div>
                <button className="DCCheckboxButton" onClick={this.toggleCheckboxValue}>
                    <span className="DCCheckboxTitle">{this.props.title}</span>
                    <img src={this.props.checked ? checkboxCheckedImage : checkboxUncheckedImage} className="DCCheckboxImage" alt="Checkbox Icon"/>
                </button>
            </div>
        );
    }
}

export default DCCheckbox;