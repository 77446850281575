import React, { Component } from 'react';
import './Invite.css';
import flowers from './resources/weddingFlowers.svg';

class Invite extends Component {

    render() {
        var url = "https://rsvp.ziyandadavid.com";
        if (this.props.params.id != null) {
            url += "/" + this.props.params.id.toUpperCase();
        }

        return (
            <div className="invite">
                <div className="inviteContainer">
                    <img className="inviteBannerImage" src={flowers} alt="Flowers"/>
                    <div className="inviteCentered">
                    </div>
                </div>

                <div className="inviteCenterBlock">
                    <div className="inviteHeadlineFont">Along with their families</div>
                    <div className="inviteHeadlineFontLarge">David & Ziyanda</div>
                    <div className="inviteHeadlineFont">invite you to celebrate their wedding</div>
                </div>

                <div className="inviteSpacer">
                </div>

                <table className="inviteTable">
                    <thead>
                        <tr>
                            <td className="inviteDateBlock" align="center" width="33%">Friday 4 October 2019</td>
                            <td className="inviteLocationBlock" align="center" width="34%">Aston Norwood, Kaitoke, NZ</td>
                            <td className="inviteTimeBlock" align="center" width="33%">3:30 O'clock in the afternoon</td>
                        </tr>
                        <tr>
                            <td className="inviteRsvpBlock" align="center" colSpan="3">
                                <a href={url}>
                                    <div className="inviteRsvpInnerBlock">
                                        RSVP
                                    </div>
                                </a>
                            </td>
                        </tr>
                    </thead>
                </table>
            </div>
        );
    }
}

export default Invite;