/**
 * Created by davidcaddy on 10/01/2017.
 */
import React from 'react';
import { Route } from 'react-router'
import Rsvp from './Rsvp';
import Invite from './Invite'

export default(
    <Route>
        <Route path="/invitation" component={Invite}/>
        <Route path="/invitation/(:id)" component={Invite}/>
        <Route path="/(:id)" component={Rsvp}/>
    </Route>
);
